import React from "react"

import Header       from "../components/Header/upcc"
import WallSchedule from "../components/upcc/wall-schedule"
import Schedule     from "../components/upcc/schedule"
import Footer       from "../components/share/footer"


export default () => {

  return (
    <>
      <Header/>

      <div class="upcc-container">

        <WallSchedule/>

        <Schedule/>

      </div>

      <Footer/>
    </>
  )
}
